import { selectAssignment } from '@redux/experiments/selectors/index';
import type { RFQGuestCountFormatAssignment } from 'types/experiments';
import type { State } from 'types/redux';
import { options as optionsSegmentedControl } from '../../../pages/Storefront/containers/InlineRFQ/components/FormBody/components/GuestCountSegmentedControl/GuestCountSegmentedControl';
import { unifyGuestNumberFormat } from '../../../pages/Storefront/containers/InlineRFQ/data/loader/utils/general';

const hasIncompatibleValue = (value: string) => {
	if (!value) return false;

	const limitedOptions = optionsSegmentedControl.map(({ value }) => value);
	const formattedValue = unifyGuestNumberFormat(value);
	const isIncompatible = !limitedOptions.includes(formattedValue);

	return isIncompatible;
};

export const rfqGuestCountFormatSelector = (state: State) => {
	const currentAssignment = selectAssignment(state, [
		'rfqGuestCountFormat',
	]) as RFQGuestCountFormatAssignment;
	const guestCountFromAccount =
		state.membership?.member?.number_of_guests || '';

	const guestCountFromLocalStorage =
		typeof localStorage !== 'undefined' && localStorage.getItem('xo_rfq_v3')
			? JSON.parse(localStorage.getItem('xo_rfq_v3') || '{}')?.guest_count
					?.display || ''
			: '';

	// Detect previous guest count values which are incompatible with new variant components
	if (
		currentAssignment !== 'slider' &&
		(hasIncompatibleValue(guestCountFromAccount) ||
			hasIncompatibleValue(guestCountFromLocalStorage))
	) {
		return null;
	}

	return currentAssignment;
};
