import classNames from 'classnames';
import React, { useEffect, useRef, useCallback } from 'react';
import Styles from './styles.scss';

interface RangeSliderProps {
	step: number;
	startingMin: number;
	startingMax: number;
	minVal: number;
	maxVal: number;
	setMinVal: (value: number) => void;
	setMaxVal: (value: number) => void;
	maxName?: string;
	shouldShowErrors?: boolean;
	shouldShowLabels?: boolean;
	shouldShowSteps?: boolean;
}

export const RangeSlider = ({
	startingMin,
	startingMax,
	minVal,
	maxVal,
	setMinVal,
	setMaxVal,
	step,
	maxName,
	shouldShowErrors = false,
	shouldShowLabels = false,
	shouldShowSteps = false,
}: RangeSliderProps) => {
	const minValRef = useRef<HTMLInputElement | null>(null);
	const maxValRef = useRef<HTMLInputElement | null>(null);
	const range = useRef<HTMLDivElement | null>(null);
	const numSteps = startingMax / step + 1;

	const getPercent = useCallback(
		(value: number) =>
			Math.round(((value + startingMin) / (startingMax - startingMin)) * 100),
		[startingMin, startingMax],
	);

	// Set width of the range to decrease from the left side
	useEffect(() => {
		if (maxValRef.current) {
			const minPercent = getPercent(minVal);
			const maxPercent = getPercent(+maxValRef.current.value);

			if (range.current) {
				range.current.style.left = `${minPercent}%`;
				range.current.style.width = `${maxPercent - minPercent}%`;
			}
		}
	}, [minVal, getPercent]);

	// Set width of the range to decrease from the right side
	useEffect(() => {
		if (minValRef.current) {
			const minPercent = getPercent(+minValRef.current.value);
			const maxPercent = getPercent(maxVal);

			if (range.current) {
				range.current.style.width = `${maxPercent - minPercent}%`;
			}
		}
	}, [maxVal, getPercent]);

	const onChangeFromRange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number.parseInt(event.target.value);
		const inRangeValue = value >= maxVal ? maxVal - step : value;
		setMinVal(inRangeValue);
	};

	const onChangeToRange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number.parseInt(event.target.value);
		const inRangeValue = value <= minVal ? minVal + step : value;
		setMaxVal(inRangeValue);
	};

	return (
		<div
			className={Styles.rangeContainer}
			style={
				{
					'--leftVal': minVal,
					'--rightVal': maxVal,
					'--minVal': startingMin,
					'--maxVal': startingMax,
					'--numSteps': numSteps,
				} as React.CSSProperties
			}
		>
			{shouldShowLabels && (
				<output
					className={classNames(
						{ [Styles.leftLabel]: shouldShowLabels },
						{
							[Styles.hasError]: shouldShowErrors,
						},
						{
							[Styles.minLeftLabel]: minVal === startingMin,
						},
					)}
				/>
			)}
			<input
				data-testid="min-range-slider"
				type="range"
				ref={minValRef}
				min={startingMin}
				max={startingMax}
				value={minVal}
				step={step}
				className={classNames(Styles.thumb, Styles.thumbMin, {
					[Styles.hasBackground]: !shouldShowLabels,
				})}
				onChange={onChangeFromRange}
				onMouseUp={(e) => e.stopPropagation()}
				tabIndex={-1}
			/>
			{shouldShowLabels && (
				<output
					className={classNames(
						{ [Styles.rightLabel]: shouldShowLabels },
						{
							[Styles.hasError]: shouldShowErrors,
						},
						{
							[Styles.maxRightLabel]: maxVal === startingMax,
						},
					)}
				/>
			)}
			<input
				data-testid="max-range-slider"
				type="range"
				ref={maxValRef}
				min={startingMin}
				max={startingMax}
				value={maxVal}
				step={step}
				className={classNames(Styles.thumb, Styles.thumbMax, {
					[Styles.hasBackground]: !shouldShowLabels,
				})}
				onChange={onChangeToRange}
				onMouseUp={(e) => e.stopPropagation()}
				tabIndex={-1}
				name={maxName}
			/>
			<div
				className={classNames(Styles.slider, {
					[Styles.showSteps]: shouldShowSteps,
				})}
			>
				<div className={Styles.sliderTrack} />
				<div
					ref={range}
					className={classNames(Styles.sliderRange, {
						[Styles.noRangeSlider]:
							shouldShowLabels &&
							minVal === startingMin &&
							maxVal === startingMax,
					})}
				/>
			</div>
		</div>
	);
};
