import { selectAssignment } from '@redux/experiments/selectors/index';
import type { UIReadabilityEnhancementsAssignment } from 'types/experiments';
import type { State } from 'types/redux';

export const uiReadabilityEnhancementsAssignmentSelector = (state: State) => {
	return selectAssignment(state, [
		'uiReadabilityEnhancements',
	]) as UIReadabilityEnhancementsAssignment;
};

export const isUIReadabilityEnhancementsSelector = (state: State) => {
	const assignment = uiReadabilityEnhancementsAssignmentSelector(state);
	return assignment === 'variant1';
};
