import { experimentAPIKey } from '@settings';
import FlipperClient from '@tkww/orion-web-sdk-experimentation';
import { isObject } from '@utils/isObject';
import { Logger } from '@utils/logger/logger';

const EXPERIMENT_VALID_STATUS_CODES = [200, 201, 204];
const client = new FlipperClient({
	apiKey: experimentAPIKey,
});

// Retry five times before logging an error.
const retryIfNecessary = async (
	anonymousId: string,
	experimentId: string,
	variantId: string,
	retries: number,
) => {
	const nextRetryCount = retries + 1;
	if (retries < 5) {
		await reportAssignment(
			anonymousId,
			experimentId,
			variantId,
			nextRetryCount,
		);
	}
};

// Caught errors have type any
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const logErrorIfNecessary = (
	context: Record<string, unknown>,
	errorOrResponse: any,
	retries: number,
) => {
	if (retries < 5) {
		return;
	}

	// Ensure the errorOrResponse data is an object before checking for object keys 'data' and 'status'
	const isResponse = isObject(errorOrResponse) && 'status' in errorOrResponse;
	const name = 'Error reporting assignment to Flipper';
	const error = isResponse ? name : errorOrResponse;
	const fingerprint = 'assignment report error';
	if (isResponse) {
		context.responseBody = JSON.stringify(errorOrResponse);
		context.responseStatus = errorOrResponse.status;
	}

	Logger.error({
		log_message: name,
		errorToLog: new Error(error),
		optionalParams: {
			...context,
			fingerprint,
		},
		newRelic: true,
	});
};
interface LogMissingIdsParams {
	response: Awaited<ReturnType<typeof client.reportAssignment>>;
	context: {
		anonymousId: string;
		experimentId: string;
		variantId: string;
	};
}

function logIfMissingIds({ response, context }: LogMissingIdsParams) {
	const { anonymousId, experimentId, variantId } = context;
	if (anonymousId && experimentId && variantId) return;
	const log_message = 'Missing Ids when reporting assignment to Flipper';

	Logger.error({
		log_message,
		errorToLog: new Error(log_message),
		optionalParams: {
			...context,
			response: JSON.stringify(response),
			status: response.status,
			fingerprint: 'assignment report error',
		},
		newRelic: true,
	});
}

export const reportAssignment = async (
	anonymousId: string,
	experimentId: string,
	variantId: string,
	retries = 0,
) => {
	const context = { anonymousId, experimentId, variantId };

	try {
		const response = await client.reportAssignment({
			bucketingId: anonymousId,
			experimentId,
			variantId,
		});

		// Response might be bad and still not throw an exception.
		// We can check this with the status code
		if (response.status < 300) {
			logIfMissingIds({ response, context });
			return;
		}

		await retryIfNecessary(anonymousId, experimentId, variantId, retries);
		logErrorIfNecessary(context, response, retries);
	} catch (err) {
		await retryIfNecessary(anonymousId, experimentId, variantId, retries);
		logErrorIfNecessary(context, err, retries);
	}
};
